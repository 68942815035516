@font-face {
  font-family: 'Helvetica';
  src: url('../__assets/fonts/helvetica-light.ttf');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica';
  src: url('../__assets/fonts/Helvetica.ttf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica';
  src: url('../__assets/fonts/Helvetica-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: 'Helvetica', sans-serif;
  
  * {
    box-sizing: border-box;
  }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
  }
  @media screen and (max-width: 767px) {
		position: fixed;
		top: 0;
		bottom: 0;

    #root, .app-wrapper {
      width: 100%;
			height: 100%;
    }
	}
}
// .app-wrapper {
//   width: 100vw;
//   height: 100vh;

//   & > div {
//     width: 100%;
//     height: 100%;
//   }
// }