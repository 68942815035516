@import '../../__assets/styles/variables.scss';

$photoSize: 46px;
$photoBorderBlue: #03a7ed;

.component__watchparty-chat-message {
  padding: 15px;
  display: flex;
  
  &[data-is-last="false"] {
    border-bottom: 1px solid rgba(213, 213, 213, 0.3);
  }
  &__user-photo {
    padding-left: 5px;
    padding-right: 5px;

    .photo-wrapper {
      width: calc(#{$photoSize} + 8px);
      height: calc(#{$photoSize} + 8px);
      background-color: $photoBorderBlue;
      border-radius: calc((#{$photoSize} + 8px) / 2);
      position: relative;
      left: -4px;
      top: -4px;
      z-index: 0;
      
      &::after {
        content: '';
        width: calc(#{$photoSize} + 4px);
        height: calc(#{$photoSize} + 4px);
        background-color: $white;
        border-radius: calc((#{$photoSize} + 4px) / 2);
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        transform: translate(-50%, -50%);
      }
      & > div {
        width: $photoSize;
        height: $photoSize;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: calc(#{$photoSize} / 2);
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        transform: translate(-50%, -50%);
      }
    }
  }
  &__message-content {
    padding-left: 5px;
    padding-right: 15px;
    flex: 1;

    .content {
      p {
        margin: 0;
      }
      .user-name {
        font-weight: 700;
        margin-bottom: 5px;
      }
      .message-image {
        width: 100%;
        height: 150px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .actions {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        height: 20px;
      }
      button {
        background-color: transparent;
        border: none;
      }
      .primary {
        flex: 1;
        display: grid;
        align-items: center;
        justify-items: start;
        grid-template-columns: 0.25fr 0.25fr;
        
        button {
          padding: 0;
          font-size: px-to-rem(16);

          img {
            vertical-align: text-bottom;
            margin-right: 5px;
          }
        }
      }
    }
  }
}