@import '../../__assets/styles/variables.scss';

$headerHeight: 120px;
$headerGray: #232324;
$headerWhite: #efefef;
$miscHeaderHeight: 32px;
$miscHeaderInviteBlue: #003255;
$miscHeaderCountGray: #424959;
$chatMessageHeight: 90px;
$photoSize: 46px;
$photoBorderBlue: #03a7ed;

.page__watchparty {
  height: 100%;
  display: flex;
  flex-direction: column;

  [contenteditable="true"] {
    &:empty:before {
      content: attr(placeholder);
      pointer-events: none;
      opacity: 0.5;
    }
    &:focus {
      outline: 0;
    }
  }
  &__header {
    padding: 15px;
    min-height: $headerHeight;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $headerGray;
    color: $headerWhite;
    font-size: px-to-rem(24);
    font-weight: 700;
    text-align: center;
    box-sizing: border-box;
  }
  &__misc-header {
    min-height: $miscHeaderHeight;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $white;
    border-bottom: 1px solid #bdbdbd;
  }
  &__hbutton {
    padding: 0;
    background-color: transparent;
    border: none;

    img {
      height: 22px;
    }
    &--chat {
      position: relative;
      left: 10px;
    }
    &--fan-count {
      font-size: px-to-rem(16);
      display: flex;
      align-items: center;

      img {
        margin-right: 8px;
        max-height: 18px;
      }
    }
  }
  &__chat-container {
    padding-bottom: 20px;
    height: 0; // solution for inner element scroll
    flex: 1;
    overflow: auto;
  }
  &__chat-message-box {
    padding: 10px;
    min-height: 60px;
    border-top: 1px solid #bdbdbd;

    .typing-area {
      margin-bottom: 10px;

      .message-input,
      .gif-input {
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: px-to-rem(16);
        background-color: $white;
        color: $headerGray;
      }
    }
    .action-area {
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      .gif-button {
        padding: 5px;
        font-size: px-to-rem(16);
        color: $white;
        background-color: $miscHeaderCountGray;
        border-radius: 7px;
      }
      .send-button img {
        height: 32px;
        vertical-align: bottom;
      }
    }
    .gif-input {
      text-align: center;
      
      .selected-gif {
        display: inline-block;
        position: relative;
  
        button {
          padding: 4px 7px;
          border: none;
          border-radius: 8px;
          box-shadow: 0 0 3px 0px $headerGray;
          position: absolute;
          top: -5px;
          right: -10px;
        }
      }
    }
  }
  &__gif-drawer {
    padding: 10px 15px 20px;
    border-top: 1px solid #bdbdbd;

    .gif-input {
      margin-bottom: 12px;
      padding: 7px 12px;
      font-size: px-to-rem(16);
      background-color: rgba(#d4d4d4, 0.35);
      border-radius: 15px;
    }
    .gif-chooser {
      height: 200px;
      overflow-y: auto;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 8px;
      row-gap: 8px;

      .gif-image {
        height: 90px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  &__members {
    overflow: auto;

    .member {
      padding: 15px;
      display: flex;

      &[data-is-last="false"] {
        border-bottom: 1px solid rgba(213, 213, 213, 0.3);
      }
      .user-photo {
        padding-left: 5px;
        padding-right: 5px;
    
        .photo-wrapper {
          width: calc(#{$photoSize} + 8px);
          height: calc(#{$photoSize} + 8px);
          background-color: $photoBorderBlue;
          border-radius: calc((#{$photoSize} + 8px) / 2);
          position: relative;
          left: -4px;
          top: -4px;
          z-index: 0;
          
          &::after {
            content: '';
            width: calc(#{$photoSize} + 4px);
            height: calc(#{$photoSize} + 4px);
            background-color: $white;
            border-radius: calc((#{$photoSize} + 4px) / 2);
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 1;
            transform: translate(-50%, -50%);
          }
          & > div {
            width: $photoSize;
            height: $photoSize;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: calc(#{$photoSize} / 2);
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 2;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
    .user-name {
      font-weight: 700;
    }
  }
}