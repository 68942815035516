@import '../../__assets/styles/variables.scss';

$photoSize: 70px;
$pageGray: #424950;

.page__home {
  display: flex;
  flex-direction: column;
  height: 100%;
  
  &__header {
    height: 80px;
    background-color: $pageGray;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-height: 75%;
    }
  }
  &__party-list {
    flex: 1;
    min-height: 0;
    overflow-y: auto;
  }
  &__party-row {
    padding: 15px;
    display: flex;
    align-items: center;
    
    &[data-is-last="false"] {
      border-bottom: 1px solid rgba(213, 213, 213, 0.3);
    }
  }
  &__party-photo {
    padding-left: 5px;
    padding-right: 5px;

    .photo-wrapper {
      width: $photoSize;
      height: $photoSize;
      
      & > div {
        width: $photoSize;
        height: $photoSize;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: calc(#{$photoSize} / 2);
      }
    }
  }
  &__party-title {
    padding-left: 5px;
    padding-right: 15px;
    flex: 1;
    font-weight: 700;
  }
}