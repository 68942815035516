@import '../../__assets/styles/variables.scss';

.component__login-popover {
  width: 100vw;
  height: 30vh;
  padding: 10px 25px;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: $white;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  box-shadow: 0 0 28px rgba(0, 83, 140, 0.24);

  &__title {
    margin-bottom: 20px;
    text-align: center;
    font-size: px-to-rem(20);
    color: #00538c;
  }
  &__social-logins {
    button {
      width: 100%;
      padding-top: 12px;
      padding-bottom: 12px;
      display: block;
      background-color: #00538c;
      color: $white;
      box-shadow: 0 3px 20px rgba(0, 83, 140, 0.24);
      border: none;
      border-radius: 10px;
      font-size: px-to-rem(18);
      font-family: 'Helvetica', sans-serif;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;

      &:first-of-type {
        margin-bottom: 12px;
      }
      img {
        height: 30px;
        margin-right: 12px;
      }
    }
  }
}